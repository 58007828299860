import Vue from 'vue';
import { abPlugin } from '@webplatform/asbis-ui';
import '@webplatform/asbis-ui/dist/assets/scss/index.scss';
import '@webplatform/asbis-ui/dist/assets/themes/prestigio/theme.scss';

Vue.config.ignoredElements = [
  'swiper-container',
  'swiper-slide',
  'sl-tab',
  'sl-tab-group',
  'sl-tab-panel',
];
// eslint-disable-next-line func-names
export default function ({ $gsap, $ScrollTrigger }) {
  Vue.use(abPlugin, {
    gsap: $gsap,
    ScrollTrigger: $ScrollTrigger,
  });
}
