import { render, staticRenderFns } from "./ProductUserHelp.vue?vue&type=template&id=f3518a0e"
import script from "./ProductUserHelp.vue?vue&type=script&lang=ts"
export * from "./ProductUserHelp.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/ui/UiIcon.vue').default,UiLink: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/ui/UiLink.vue').default,ProductUserHelpDownloads: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/sections/productSection/ProductUserHelpDownloads.vue').default,AppFaq: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/AppFaq.vue').default,ProductUserHelpVideos: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/sections/productSection/ProductUserHelpVideos.vue').default})
