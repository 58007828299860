export default {
  __name: 'ProductCertificates',
  props: {
  data: {
    type: Object,
    default: () => ({}),
  },
},
  setup(__props) {



return { __sfc: true, }
}

}