
import { defineComponent } from '@nuxtjs/composition-api';
import CatalogCategoryItem from '~/components/sections/categorySection/CatalogCategoryItem.vue';

const sizes: { [size: string]: Record<number, string> } = {
  width: {
    1: 'w-full sm:w-1/3',
    1.5: 'w-full sm:w-1/2',
  },
  height: 'h-[296px] sm:h-[456px]',
};

export default defineComponent({
  components: { CatalogCategoryItem },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {
      sizes,
    };
  },
});
