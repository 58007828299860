import { computed, Ref, UnwrapRef, useContext } from '@nuxtjs/composition-api';

const isAbsolutePath = (src: string) => {
  return /^https?:\/\//.test(src);
};

export const getAssetUrl = (
  asset?: string,
  modifiers = {},
  isVideo = false,
): string | null => {
  const { $img } = useContext();
  if (!asset) return null;
  if (!isVideo) return $img(asset, modifiers, { provider: 'imgproxy' });
  return isAbsolutePath(asset)
    ? asset
    : $img(asset, modifiers, { provider: 'imgproxy' });
};

export const useMediaAsset = (
  asset: Ref<string>,
  modifiers = {},
): Ref<UnwrapRef<string>> | Ref<UnwrapRef<null>> => {
  return computed(() => getAssetUrl(asset.value, modifiers));
};
