
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import useBreakpoint from '~/composables/useBreakpoint';
import { useElementHover } from '@vueuse/core';
import CatalogCategoryItemImages from '~/components/sections/categorySection/CatalogCategoryItemImages.vue';

const variations: Record<string, { [key: string]: string }> = {
  title: {
    default: 'tracking-[0.12em] text-xs text-slate sm:text-sm 2xl:text-lg',
    catalog: 'text-xl md:text-3xl',
  },
};

export default defineComponent({
  components: { CatalogCategoryItemImages },
  props: {
    link: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    image: {
      type: [Object, String],
      required: false,
      default: () => ({}),
    },
    images: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    styles: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    variation: {
      type: String,
      default: 'default',
    },
  },
  setup(props) {
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('md');
    const el = ref();
    const hover = useElementHover(el as any);

    const titleClasses = computed(() => variations.title[props.variation]);

    return {
      titleClasses,
      isDesktop,
      el,
      hover,
    };
  },
});
