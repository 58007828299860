
import {
  defineComponent,
  nextTick,
  reactive,
  ref,
  useStore,
  useContext,
  watch,
} from '@nuxtjs/composition-api';

import useForm from '~/composables/useForm';
import useCountries from '~/composables/useCountries';
import useRecaptcha from '~/composables/useRecaptcha';
import useModalDialog from '~/composables/useModalDialog';

interface ConsultationForm {
  type: string;
  site: string;
  page_url?: string;
  lang: string;
  name: string;
  email: string;
  phone: string;
  country: string;
  message: string;
  personalData: boolean;
}

export default defineComponent({
  components: {},
  setup() {
    const store = useStore();
    const config = store.getters['config/getConfig'];
    const lang: string = store.getters['config/getActiveLanguage'];
    const form = ref();
    const { formSuccess, formPending, submit } = useForm();
    const recaptcha = useRecaptcha();
    const { $gtm } = useContext();
    const {
      isOpen: infoModalIsOpen,
      open: openInfoModal,
      close: closeInfoModal,
    } = useModalDialog('info');

    const formData = reactive<ConsultationForm>({
      type: 'prsol_consult',
      site: config.domain,
      lang,
      name: '',
      email: '',
      phone: '',
      country: '',
      message: '',
      personalData: false,
    });

    const clearForm = () => {
      confirm.value = false;

      const formDataKeys: Array<
        keyof Omit<ConsultationForm, 'type' | 'site' | 'lang'>
      > = ['name', 'email', 'phone', 'country', 'message'];
      formDataKeys.forEach((key) => {
        if (typeof formData[key] === 'string') (formData[key] as string) = '';
        if (typeof formData[key] === 'boolean')
          (formData[key] as boolean) = false;
      });

      nextTick(() => form.value.reset());
    };
    const isFormStartEventSended = ref(false);
    const confirm = ref(false);
    const { getCountriesList } = useCountries();
    const countries = getCountriesList();

    const submitForm = async () => {
      const isValid = await recaptcha.validate();
      if (!isValid) return;
      await submit({ ...formData, page_url: window.location.href });
      $gtm.push({
        event: 'leadform_sent',
      });
      openInfoModal();
      clearForm();
    };

    const pushFormStartEvent = () => {
      if (!isFormStartEventSended.value) {
        $gtm.push({
          event: 'form_start',
        });
      }
      isFormStartEventSended.value = true;
    };

    watch(formData, () => {
      pushFormStartEvent();
    });
    watch(confirm, () => {
      pushFormStartEvent();
    });

    return {
      form,
      formData,
      confirm,
      countries,
      formSuccess,
      formPending,
      submitForm,
      recaptcha,
      infoModalIsOpen,
      closeInfoModal,
    };
  },
});
