
import { computed, defineComponent } from '@nuxtjs/composition-api';
import useBreakpoint from '~/composables/useBreakpoint';
import { getAssetUrl } from '~/composables/useMediaAsset';
import handleImageAlt from '~/helpers/handleImageAlt';
import handleImageSrc from '~/helpers/handleImageSrc';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('md');

    const markersBoardImageStyles = computed(() => {
      const styles = props.data.markersBoard?.styles;
      return isDesktop.value ? styles?.desktop : styles.mobile;
    });

    return {
      isDesktop,
      markersBoardImageStyles,
      getAssetUrl,
      handleImageAlt,
      handleImageSrc,
    };
  },
});
