import { ref, useContext } from '@nuxtjs/composition-api';

const useRecaptcha = () => {
  const { $axios, $recaptcha } = useContext();
  const id = Math.random().toString(36);
  const widgetId = ref();
  const isBusy = ref(false);
  const token = ref('');

  const validateToken = async (t: string) => {
    const response = await $axios.post('/check-recaptcha-token', { token: t });
    return response.data;
  };

  const reset = () => {
    isBusy.value = false;
    $recaptcha.reset();
  };

  const init = async () => {
    await $recaptcha.init();

    widgetId.value = $recaptcha.render(id, {
      // @ts-ignore
      sitekey: $recaptcha.siteKey,
    });
  };

  const validate = async () => {
    isBusy.value = true;

    try {
      const result = await validateToken(token.value);
      return result?.success === true;
    } catch (error) {
      return false;
    } finally {
      reset();
    }
  };

  const setToken = (t = '') => {
    token.value = t;
  };

  return {
    id,
    init,
    token,
    validate,
    setToken,
    isBusy,
  };
};

export default useRecaptcha;
