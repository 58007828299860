
import { defineComponent, useContext } from '@nuxtjs/composition-api';
import handleImageAlt from '~/helpers/handleImageAlt';
import handleImageSrc from '~/helpers/handleImageSrc';

export default defineComponent({
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { $config } = useContext();

    const filterLink = (path: string) => {
      const isExternalLink = /^https?:/.test(path);

      if (isExternalLink) {
        return path;
      }

      return `${$config.fullPath}${path}`;
    };

    return {
      filterLink,
      handleImageAlt,
      handleImageSrc,
    };
  },
});
