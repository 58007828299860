
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {};
  },
});
