import { ref, useContext } from '@nuxtjs/composition-api';

const useForm = () => {
  const { $api } = useContext();
  const formSuccess = ref(false);
  const formError = ref(false);
  const formPending = ref(false);

  const submit = async (formData: Record<string, any>) => {
    formPending.value = true;
    try {
      await $api.formsService.sendFormData(formData);
      formSuccess.value = true;
    } catch (error) {
      formError.value = true;
    } finally {
      formPending.value = false;
    }
  };

  return {
    formSuccess,
    formPending,
    submit,
  };
};

export default useForm;
