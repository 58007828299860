import type { GetterTree, ActionTree, MutationTree } from 'vuex';
import type { RootState } from '@/store';

interface ConfigSiteLanguage {
  code: string;
  name: string;
  locale?: string;
  default: boolean;
}

interface AppConfigSite {
  domain: string;
  language: ConfigSiteLanguage[];
}

interface AppConfigSocialLink {
  id: number;
  site_id: number;
  url: string;
}
interface AppStateConfig {
  sites: AppConfigSite[];
  language: ConfigSiteLanguage[];
  languages: ConfigSiteLanguage[];
  domain: string;
  name: string;
  facebook: string;
  instagram: string;
  vkontakte: string;
  linkedin: string;
  gtm: string;
  contacts: string[];
  social_links: AppConfigSocialLink[];
}

export const state = () => ({
  config: {
    language: [
      {
        code: 'en',
        name: 'English',
        default: true,
      },
    ],
  } as Partial<AppStateConfig>,
});

type ConfigState = ReturnType<typeof state>;

export const getters: GetterTree<ConfigState, RootState> = {
  getActiveLanguage: (state): string =>
    state.config.language!.find((l) => l.default)!.code,
  getConfig: (state): Partial<AppStateConfig> => state.config,
  getSocials: (state) => {
    const socialList: { url: string; name: string }[] = [];
    const oldSocialList = state.config.social_links?.map((socialItem) => {
      let iconName = '';
      if (socialItem.url.includes('youtube') || socialItem.url.includes('yt'))
        iconName = 'youtube';
      if (
        socialItem.url.includes('facebook') ||
        socialItem.url.includes('fb.com')
      )
        iconName = 'facebook';
      if (
        socialItem.url.includes('twitter') ||
        socialItem.url.includes('x.com')
      )
        iconName = 'twitter';
      if (socialItem.url.includes('linkedin')) iconName = 'linkedin';
      if (socialItem.url.includes('instagram')) iconName = 'instagram';
      return {
        url: socialItem.url,
        name: iconName,
      };
    });
    ['instagram', 'facebook', 'twitter', 'youtube', 'linkedin'].forEach(
      (socialName) => {
        const seekedSocialItem = oldSocialList?.find(
          (socialItem) => socialItem.name === socialName,
        );
        if (seekedSocialItem) socialList.push(seekedSocialItem);
      },
    );

    return socialList;
  },
  getGTM(state) {
    return state.config.gtm;
  },
};

export const mutations: MutationTree<ConfigState> = {
  setConfig: (state, config: AppStateConfig) => (state.config = config),
};

export const actions: ActionTree<ConfigState, RootState> = {
  async load({ commit }, { $api }) {
    try {
      const { data } = await $api.siteInfoService.getSiteInfo({});
      commit('setConfig', data);
    } catch (error) {
      // @ts-ignore
      console.error(error?.responce);
    }
  },
};

export { AppStateConfig };
