
import { defineComponent, computed } from '@nuxtjs/composition-api';
import useBreakpoint from '~/composables/useBreakpoint';
import CatalogCategoryItem from '~/components/sections/categorySection/CatalogCategoryItem.vue';

type CategoryItem = {
  [key: string]: string | any;
  order?: number;
  orderMobile?: number;
};

const sizes: { [size: string]: Record<number, string> } = {
  width: {
    1: 'w-1/2 md:w-[25%]',
    1.5: 'w-1/2 md:w-[37.5%]',
    2: 'w-full',
  },
  height: {
    1: 'h-[49vw] sm:h-[222px] md:h-[206px] lg:h-[251px] xl:h-[276px] 2xl:h-[309px] 3xl:h-[373px]',
    2: 'h-[49vw] sm:h-[666px] md:h-[412px] lg:h-[502px] xl:h-[552px] 2xl:h-[618px] 3xl:h-[746px]',
  },
};

export default defineComponent({
  components: { CatalogCategoryItem },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('md');
    const items = computed(() =>
      (props.data as CategoryItem[])
        .map((item, i) => {
          const order = item.order ?? i;
          const orderMobile = item.orderMobile ?? order;

          return { ...item, order, orderMobile };
        })
        .sort((a, b) =>
          isDesktop.value ? a.order - b.order : a.orderMobile - b.orderMobile,
        ),
    );

    const isWide = (i: number) =>
      i === items.value.length - 1 &&
      !isDesktop.value &&
      items.value.length % 2;

    return {
      items,
      isDesktop,
      sizes,
      isWide,
    };
  },
});
