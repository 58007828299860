
import {
  defineComponent,
  nextTick,
  onMounted,
  ref,
} from '@nuxtjs/composition-api';
import useBreakpoint from '~/composables/useBreakpoint';
import handleImageSrc from '~/helpers/handleImageSrc';
import handleImageAlt from '~/helpers/handleImageAlt';

export default defineComponent({
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { isBiggerThanBreakpoint: isBiggerMd } = useBreakpoint('lg');
    const swiperMain = ref();
    const swiperThumbs = ref();
    const isSwiperMainInit = ref(false);
    const isSwiperThumbsInit = ref(false);

    const initSlidersController = () => {
      nextTick(() => {
        const $swiperMain = swiperMain.value.$swiper;
        const $swiperThumbs = swiperThumbs.value.$swiper;

        $swiperMain.controller.control = $swiperThumbs;
        $swiperThumbs.controller.control = $swiperMain;
      });
    };

    onMounted(() => {
      initSlidersController();
    });

    const swiperOptions = {
      main: {
        on: {
          /* eslint-disable */
          init: function () {
            isSwiperMainInit.value = true;
          },
          /* eslint-enable */
        },
      },
      thumbs: {
        slidesPerView: 'auto',
        spaceBetween: 16,
        loop: false,
        lazy: {
          enabled: true,
          checkInView: true,
          loadPrevNext: true,
          loadPrevNextAmount: 3,
        },
        breakpoints: {
          1024: {
            direction: 'vertical',
            spaceBetween: 8,
          },
        },
        on: {
          /* eslint-disable */
          init: function () {
            isSwiperThumbsInit.value = true;
          },
          /* eslint-enable */
        },
      },
    };

    const slideTo = (i: number) => {
      swiperMain.value?.$swiper.slideTo(i);
    };

    return {
      isSwiperMainInit,
      isSwiperThumbsInit,
      swiperMain,
      swiperThumbs,
      isBiggerMd,
      swiperOptions,
      slideTo,
      handleImageSrc,
      handleImageAlt,
    };
  },
});
