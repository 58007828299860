const middleware = {}

middleware['noTrailingSlashRedirect'] = require('../middleware/noTrailingSlashRedirect.js')
middleware['noTrailingSlashRedirect'] = middleware['noTrailingSlashRedirect'].default || middleware['noTrailingSlashRedirect']

middleware['recaptcha'] = require('../middleware/recaptcha.js')
middleware['recaptcha'] = middleware['recaptcha'].default || middleware['recaptcha']

middleware['set-user-device'] = require('../middleware/set-user-device.js')
middleware['set-user-device'] = middleware['set-user-device'].default || middleware['set-user-device']

export default middleware
