import { computed } from 'vue';


export default {
  __name: 'UiTag',
  props: {
  active: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: 'blue',
  },
  uppercase: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'lg',
  },
  rounded: {
    type: String,
    default: '2xl',
  },
  sans: {
    type: Boolean,
    default: false,
  },
  static: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const styles = {
  colors: {
    blue: {
      base: 'text-blue',
      hover: 'hover:bg-blue hover:text-white',
      active: 'bg-blue !text-white',
    },
    white: {
      base: 'text-white',
      hover: 'hover:text-blue',
      active: '!text-blue',
    },
    gray: {
      base: 'text-slate-lighten4 border-gray-lighten1',
    },
    slate: {
      base: 'text-slate',
    },
  },
  sizes: {
    md: 'min-h-[24px] py-1 px-2 text-xs',
    lg: 'min-h-[28px] py-[5px] px-2.5 text-sm',
  },
  roundings: {
    lg: 'rounded-lg',
    '2xl': 'rounded-2xl',
  },
};



const tagClasses = computed(() => {
  const color = styles.colors[props.color];

  const classes = [
    styles.sizes[props.size],
    styles.roundings[props.rounded],
    color.base,
    props.sans ? 'font-sans' : 'font-segoe',
  ];

  if (!props.static && color.hover) classes.push(color.hover);
  if (props.active && color.active) classes.push(color.active);
  if (props.uppercase) classes.push('uppercase');

  return classes;
});

return { __sfc: true,styles, props, tagClasses }
}

}