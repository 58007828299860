
import { defineComponent } from '@nuxtjs/composition-api';
import { getAssetUrl } from '~/composables/useMediaAsset';
import handleImageAlt from '~/helpers/handleImageAlt';
import handleImageSrc from '~/helpers/handleImageSrc';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      getAssetUrl,
      handleImageAlt,
      handleImageSrc,
    };
  },
});
