
import { defineComponent, useStore } from '@nuxtjs/composition-api';

import FooterMainMenu from '@/domains/menus/components/FooterMainMenu.vue';
import FooterBottomMenu from '@/domains/menus/components/FooterBottomMenu.vue';
import SocialsList from '@/components/SocialsList.vue';

export default defineComponent({
  components: {
    FooterMainMenu,
    SocialsList,
    FooterBottomMenu,
  },
  props: {
    menuFooter: {
      type: Array,
      default: () => [],
    },
    menuBottom: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const store = useStore();
    const socialNetworks = store.getters['config/getSocials'];

    return {
      socialNetworks,
    };
  },
});
