import { readBody } from 'h3';
import { $fetch } from 'ohmyfetch';

const IS_DEV = process.env.NODE_ENV === 'development';
const SECRET_KEY =
  process.env.RECAPTCHA_SECRET_KEY ||
  '6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe';

export default async (req, res) => {
  res.setHeader('Content-Type', 'application/json');

  // eslint-disable-next-line no-unreachable
  try {
    const { token } = await readBody({ node: { req }, method: 'POST' });

    if (!token) {
      res.end(
        JSON.stringify({
          success: false,
          message: 'Invalid token',
        }),
      );
      return;
    }

    const response = await $fetch(
      `https://www.google.com/recaptcha/api/siteverify?secret=${SECRET_KEY}&response=${token}`,
      { method: 'POST' },
    );

    res.end(
      JSON.stringify({
        success: response.success,
        response,
      }),
    );
  } catch (error) {
    if (IS_DEV) console.error('ReCaptcha error:', error);

    res.end(
      JSON.stringify({
        success: false,
        message: 'Internal error',
      }),
    );
  }
};
