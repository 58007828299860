
import { defineComponent } from '@nuxtjs/composition-api';
import UiLink from '~/components/ui/UiLink.vue';
import UiSectionTitle from '~/components/ui/UiSectionTitle.vue';

export default defineComponent({
  components: { UiSectionTitle, UiLink },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {};
  },
});
