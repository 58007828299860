
import {
  computed,
  defineComponent,
  onMounted,
  useContext,
  useStore,
} from '@nuxtjs/composition-api';

import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';
import AppTopbar from '@/components/AppTopbar.vue';
import { AppMenu } from 'store/menus';
import usePagePreloader from '@/composables/usePagePreloader';
import AppUpButton from '@/components/AppUpButton.vue';

export default defineComponent({
  components: {
    AppHeader,
    AppFooter,
    AppUpButton,
    AppTopbar,
  },
  setup() {
    const { route, $config } = useContext();
    const store = useStore();
    const pagePreloader = usePagePreloader();

    const isHomepage = computed(() => route.value?.fullPath === '/');

    const menuTop: AppMenu[] = store.getters['menus/menuTop'];
    const menuMain: AppMenu[] = store.getters['menus/menuMain'];
    const menuFooter: AppMenu[] = store.getters['menus/menuFooter'];
    const menuBottom: AppMenu[] = store.getters['menus/menuBottom'];

    onMounted(() => {
      // eslint-disable-next-line no-console
      console.info($config.buildInfo);
    });

    return {
      isHomepage,
      menuTop,
      menuMain,
      menuFooter,
      menuBottom,
      pagePreloader,
    };
  },
  // @ts-ignore
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    const { googleGtagId } = this.$config;
    const scripts = [];

    if (googleGtagId)
      scripts.push({
        src: `https://www.googletagmanager.com/gtag/js?id=${googleGtagId}`,
        async: true,
      });

    return {
      title: 'Prestigio solutions',
      htmlAttrs: {
        ...i18nHead.htmlAttrs,
      },
      bodyAttrs: {
        class: 'theme-prsol',
      },
      meta: [...i18nHead.meta],
      link: [...i18nHead.link],
      script: scripts,
    };
  },
});
