
import { computed, defineComponent } from '@nuxtjs/composition-api';
import useBreakpoint from '~/composables/useBreakpoint';
import { getAssetUrl } from '~/composables/useMediaAsset';
import handleImageAlt from '~/helpers/handleImageAlt';
import handleImageSrc from '~/helpers/handleImageSrc';

export default defineComponent({
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { isBiggerThanBreakpoint: isDesktop } = useBreakpoint('lg');

    const swiperOptions = {
      navigation: {
        prevEl: '.nav-prev',
        nextEl: '.nav-next',
      },
      lazy: {
        enabled: true,
        checkInView: true,
        loadPrevNext: true,
        loadOnTransitionStart: true,
      },
      loop: true,
      slidesPerView: 1,
      spaceBetween: 16,
      observer: true,
    };

    const showNav = computed(
      () => isDesktop.value && props.data && props.data.length > 1,
    );

    return {
      isDesktop,
      swiperOptions,
      showNav,
      getAssetUrl,
      handleImageSrc,
      handleImageAlt,
    };
  },
});
