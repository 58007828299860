
import { defineComponent } from '@nuxtjs/composition-api';
import { getAssetUrl } from '~/composables/useMediaAsset';
import handleImageAlt from '~/helpers/handleImageAlt';
import handleImageSrc from '~/helpers/handleImageSrc';

export default defineComponent({
  props: {
    image: {
      type: [Object, String],
      required: false,
      default: () => ({}),
    },
    images: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    isHover: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const getImage = (image: string | object) =>
      getAssetUrl(handleImageSrc(image));

    const getStyles = (type: string, hover: boolean = false) =>
      props.styles?.[hover ? 'hover' : 'base']?.[type];

    return {
      getImage,
      getStyles,
      handleImageSrc,
      handleImageAlt,
    };
  },
});
