
import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import handleImageAlt from '~/helpers/handleImageAlt';
import handleImageSrc from '~/helpers/handleImageSrc';
import {
  getProductImage,
  getProductTags,
  getProductDescription,
} from '~/helpers/productContent';

import useFetchProducts, {
  ProductExtended,
} from '~/composables/useFetchProducts';
import type { Product } from '@webplatform/nuxt-cms-js-sdk';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { fetchProductsByIds } = useFetchProducts();
    const items = ref<ProductExtended[]>([]);

    const swiperOptions = {
      observer: true,
      slidesPerView: 'auto',
      spaceBetween: 16,
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
        dragSize: 180,
        snapOnRelease: true,
      },
    };

    const setupProduct = (product: Product): ProductExtended => {
      return {
        ...product,
        description: getProductDescription(product),
        tags: getProductTags(product),
        image: getProductImage(product),
      } as ProductExtended;
    };

    watch(
      () => props.data.productIds,
      async (ids) => {
        if (!ids?.length) return;
        try {
          const data = await fetchProductsByIds(ids as string[]);
          if (data) items.value = data.map(setupProduct);
        } catch (e) {}
      },
      { immediate: true },
    );

    return {
      items,
      swiperOptions,
      handleImageSrc,
      handleImageAlt,
    };
  },
});
