
import { defineComponent, computed } from '@nuxtjs/composition-api';
import useBreakpoint from '~/composables/useBreakpoint';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { isBiggerThanBreakpoint: mdAndUp } = useBreakpoint('md');
    const banner = computed(() => {
      let image = props.data.banner.src;
      if (!mdAndUp.value) {
        image =
          props.data.banner.mobile && props.data.banner.mobile.src
            ? props.data.banner.mobile.src
            : props.data.banner.src;
      }
      return image;
    });
    const bannerSizes = computed(() => {
      if (mdAndUp.value)
        return {
          width: 1824,
          height: 240,
        };
      return {
        width: 654,
        height: 560,
      };
    });
    return {
      mdAndUp,
      banner,
      bannerSizes,
    };
  },
});
